<template>
  <div v-loading="loading">
    <el-breadcrumb
      class="m_tb_10"
      separator="/">
      <el-breadcrumb-item :to="{ path: '/party/index' }">
        首页
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ title }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="resource-box m_tb_20">
      <router-link
        class="resource-item fs_16 p_lr_10 text_hidden pointer bg_fff flex_row"
        v-for="data in resourceData"
        :key="data.id"
        :to="{
          path: '/party/content',
          query: {
            from: 9,
            cid: data.id,
            id: $route.query.id,
            type: $route.query.type,
          },
        }">
        <span class="title-box flex_row">{{ data.title }}</span>
        <span>{{ data.createTime.split(' ')[0] }}</span>
      </router-link>
    </div>
    <div class="paging">
      <el-pagination
        @current-change="current_change"
        :page-size="params.size"
        :background="true"
        layout="total, prev, pager, next"
        :total="params.total"></el-pagination>
    </div>
  </div>
</template>

<script>
import { getPartyResources } from '@/api/party/index';
export default {
  name: 'More',
  data() {
    return {
      title: '时政要闻',
      params: {
        current: 1,
        size: 10,
        contentId: null,
        total: 0,
      },
      resourceData: [],
      loading: true,
    };
  },
  async mounted() {
    let query = this.$route.query;
    console.log(query);
    this.title = query.type === 1 ? '时政要闻' : '武侯动态';
    this.params.contentId = query.id;
    this.resourceData = await this.getResourceData();
  },
  methods: {
    async getResourceData() {
      this.loading = true;
      let res = await getPartyResources(this.params);
      this.params.total = res.data.total;
      this.loading = false;
      return res.data.records;
    },
    // 分页
    current_change(current) {
      this.params.current = current;
      this.getResourceData();
    },
  },
};
</script>

<style scoped>
.resource-box {
  width: 100%;
}
.resource-item {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: space-between;
}
.title-box {
  align-items: center;
}
.title-box::before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #dd2622;
  border-radius: 50%;
  display: inline-block;
  margin-right: 20px;
}
.paging {
  width: 100%;
  text-align: right;
  margin-top: 10px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #dd2622;
}
</style>
