var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('el-breadcrumb',{staticClass:"m_tb_10",attrs:{"separator":"/"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/party/index' }}},[_vm._v(" 首页 ")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.title))])],1),_c('div',{staticClass:"resource-box m_tb_20"},_vm._l((_vm.resourceData),function(data){return _c('router-link',{key:data.id,staticClass:"resource-item fs_16 p_lr_10 text_hidden pointer bg_fff flex_row",attrs:{"to":{
        path: '/party/content',
        query: {
          from: 9,
          cid: data.id,
          id: _vm.$route.query.id,
          type: _vm.$route.query.type,
        },
      }}},[_c('span',{staticClass:"title-box flex_row"},[_vm._v(_vm._s(data.title))]),_c('span',[_vm._v(_vm._s(data.createTime.split(' ')[0]))])])}),1),_c('div',{staticClass:"paging"},[_c('el-pagination',{attrs:{"page-size":_vm.params.size,"background":true,"layout":"total, prev, pager, next","total":_vm.params.total},on:{"current-change":_vm.current_change}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }